import { render, staticRenderFns } from "./ModalProperties.vue?vue&type=template&id=2acf2c31&"
import script from "./ModalProperties.vue?vue&type=script&lang=js&"
export * from "./ModalProperties.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./ModalProperties.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VForm,VListItemContent,VSelect,VTextField})
